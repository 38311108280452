import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import "./utils/i18next";
import store from "./redux";
import Loader from "./components/Loader/Loader";

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <Suspense fallback={<Loader />}>
        <Provider store={store}>
          <App />
        </Provider>
      </Suspense>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
