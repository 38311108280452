export const SET_SIGN_UP_EMAIL = "SET_SIGN_UP_EMAIL";
export const GET_SIGN_UP_EMAIL = "GET_SIGN_UP_EMAIL";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const LOG_OUT_SIGN_UP = "LOG_OUT_SIGN_UP";
export const SET_ERRORS = "SET_ERRORS";
export const SET_RESET_EMAIL = "SET_RESET_EMAIL";

const initialState = {
  signUp: {
    email: "",
    verified: false,
  },
  resetPass: {
    email: "",
  },
  errors: null,
};

export default function signUpReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SIGN_UP_EMAIL:
      return {
        ...state,
        signUp: {
          ...state.signUp,
          email: action.email,
        },
      };
    case VERIFY_EMAIL:
      return {
        ...state,
        signUp: {
          ...state.signUp,
          verified: true,
        },
      };

    case LOG_OUT_SIGN_UP:
      return {
        ...state,
        signUp: {
          email: "",
          verified: false,
        },
        errors: null,
      };

    case SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };

    case SET_RESET_EMAIL:
      return {
        ...state,
        resetPass: {
          email: action.email,
        },
      };

    default:
      return state;
  }
}

export const setSigupEmail = (email) => ({ type: SET_SIGN_UP_EMAIL, email });

export const setResetPassEmail = (email) => ({
  type: SET_RESET_EMAIL,
  email,
});

export const fetchEmail = (payload, alert, t) => ({ type: GET_SIGN_UP_EMAIL, payload, alert, t });

export const verifyEmail = () => ({
  type: VERIFY_EMAIL,
});

export const logOut = () => ({ type: LOG_OUT_SIGN_UP });

export const addError = (errors) => ({ type: SET_ERRORS, errors });
