import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.png";
import s from "./Logo.module.css";

const Logo = () => {
  const history = useNavigate();
  return (
    <div className={s.logoWrapper} onClick={() => history("/dashboard")}>
      <img src={logo} />
    </div>
  );
};

export default Logo;
