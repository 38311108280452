import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addError,
  setResetPassEmail,
} from "../../redux/reducers/signUpReducer";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import s from "./ResetPassword.module.css";
import {
  RESEND_VERIFY_CODE,
  SET_NEW_PASSWORD,
} from "../../redux/sagas/signUpSaga";
import { AlertContext } from "../../App";

const ResetPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [type, setType] = useState({ pass: "password", repeat: "password" });
  const [canResend, setCanResend] = useState(true);
  const [timer, setTimer] = useState(0);
  const [passwordValidate, setPasswordValidate] = useState(false);
  const [resizeSmall, setResizeSmall] = useState(true);
  const [resizeBig, setResizeBig] = useState(false);

  const storedEmail = useSelector((state) => state.signUp.resetPass.email);
  const alert = useContext(AlertContext);

  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    const listenResizeEvent = () => {
      window.innerWidth <= 700 ? setResizeSmall(true) : setResizeSmall(false);
      window.innerWidth > 700 ? setResizeBig(true) : setResizeBig(false);
    }
    listenResizeEvent();
    window.addEventListener("resize", listenResizeEvent, {passive: true});
    return () => window.removeEventListener("resize", listenResizeEvent);
  }, []);

  useEffect(() => {
    dispatch(addError(null));
    return () => {
      dispatch(setResetPassEmail(""));
      dispatch(addError(null));
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
      code: "",
    },
    onSubmit: (values) => {
      if (
        values.password.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/
        ) !== null
      ) {
        dispatch({
          type: SET_NEW_PASSWORD,
          values: { ...values, email: storedEmail.toLocaleLowerCase() },
          history,
          alert,
          t,
        });
      } else if (
        !values.password.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/
        )
      ) {
        setPasswordValidate(true);
      }
    },
  });

  useEffect(() => {
    if (timer !== 0 && timer >= 1) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 990);
    }
  }, [timer]);

  const resendCodeOnClick = () => {
    if (canResend) {
      dispatch({
        type: RESEND_VERIFY_CODE,
        email: storedEmail.toLocaleLowerCase(),
        lang: localStorage.getItem("i18nextLng"),
        alert,
        t,
      });
      setCanResend(false);
      setTimer(10);

      setTimeout(() => {
        setCanResend(true);
      }, 10000);
    }
  };

  const getValidateStyles = (el) => {
    if (el === 0) {
      if (
        formik.values.password?.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/
        )
      ) {
        return { color: "#2ac769" };
      } else {
        return { color: "#323232" };
      }
    } else if (el === 1) {
      if (formik.values.password?.match(/(?=.*\d)/)) {
        return { color: "#2ac769" };
      }
    } else if (el === 2) {
      if (formik.values.password?.match(/(?=.*[a-z])/)) {
        return { color: "#2ac769" };
      }
    } else if (el === 3) {
      if (formik.values.password?.match(/(?=.*[A-Z])/)) {
        return { color: "#2ac769" };
      }
    } else if (el === 4) {
      if (formik.values.password?.match(/(?=.*[@$!%*?&#^])/)) {
        return { color: "#2ac769" };
      }
    } else if (el === 5) {
      if (formik.values.password?.length > 7) {
        return { color: "#2ac769" };
      }
    } else if (el === "i") {
      if (
        !passwordValidate &&
        formik.values.password !== formik.values.confirm_password &&
        formik.values.confirm_password.length
      ) {
        return { border: "1px solid #ff6464" };
      } else if (
        passwordValidate &&
        formik.values.password?.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/
        )
      ) {
        return { border: "1px solid #2ac769" };
      } else if (passwordValidate && !formik.values.password) {
        return { border: "1px solid #2ac769" };
      } else if (passwordValidate) {
        return { border: "1px solid #ff6464" };
      }
    }
  };

  if (!storedEmail) {
    return (
      <>
        <div className={s.wrapper}>
          <div className={s.resetPassword}>
            <div className={s.resetPasswordForm}>
              <div className={s.title}>
                <span>{t("resetPassword.title")}</span>
              </div>
              <div className={s.inputBlock} style={{ marginTop: "14px" }}>
                <label htmlFor="code">{t("resetPassword.email")}</label>
                <input
                  onChange={(e) => {
                    if (!e.target.value.includes(" ")) {
                      setEmail(e.target.value);
                    }
                  }}
                  value={email}
                  type={"text"}
                  id="email"
                  name="email"
                  placeholder={t("resetPassword.emailPlaceholder")}
                />
              </div>

              <div className={s.resetPasswordBtnWrapper}>
                <button
                  disabled={!email}
                  className={s.resetPasswordBtn}
                  onClick={() => {
                    dispatch({
                      type: RESEND_VERIFY_CODE,
                      email: email.toLocaleLowerCase(),
                      lang: localStorage.getItem("i18nextLng"),
                      alert,
                      t,
                    });
                  }}
                >
                  {t("resetPassword.next")}
                </button>
              </div>
              <div className={s.resetPasswordWrapper}>
                <span className={s.noProfile}>
                  {t("resetPassword.haveProfile")}
                </span>
                <Link to="/login" className={s.noProfileLink}>
                  <span>{t("resetPassword.enter")}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.resetPassword}>
          <div className={s.resetPasswordForm}>
            <div className={s.title}>
              <span>{t("resetPassword.title")}</span>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className={s.inputBlock} style={{ marginTop: "14px" }}>
                <label htmlFor="email">{t("resetPassword.newPassword")}</label>
                <input
                  onChange={(e) => {
                    if (!e.target.value.includes(" ")) {
                      formik.handleChange(e);
                    }
                  }}
                  value={formik.values.password}
                  type={type.pass}
                  id="password"
                  name="password"
                  placeholder={t("resetPassword.newPasswordPlaceholder")}
                  style={getValidateStyles("i")}
                  onFocus={() => setPasswordValidate(true)}
                  onBlur={() => setPasswordValidate(false)}
                />
                <div
                  onClick={() =>
                    type.pass === "password"
                      ? setType((state) => {
                          return { ...state, pass: "text" };
                        })
                      : setType((state) => {
                          return { ...state, pass: "password" };
                        })
                  }
                  className={s.typeChangeBtn}
                >
                  {type.pass === "password" ? <FaEyeSlash /> : <FaEye />}
                </div>

                {passwordValidate && resizeBig ? (
                  <div className={s.passValidateWrapper}>
                    <span style={getValidateStyles(0)}>
                      {t("errors.passContain")}
                    </span>
                    <ul>
                      <li style={getValidateStyles(5)}>
                        {t("errors.minLength")}
                      </li>
                      <li style={getValidateStyles(1)}>{t("errors.num")}</li>
                      <li style={getValidateStyles(2)}>{t("errors.lett")}</li>
                      <li style={getValidateStyles(3)}>
                        {t("errors.uppLett")}
                      </li>
                      <li style={getValidateStyles(4)}>
                        {t("errors.specSymb")}
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
              {passwordValidate && resizeSmall ? (
                <div
                  className={s.passValidateWrapper}
                  style={{ marginTop: "6px" }}
                >
                  <span style={getValidateStyles(0)}>
                    {t("errors.passContain")}
                  </span>
                  <ul>
                    <li style={getValidateStyles(5)}>
                      {t("errors.minLength")}
                    </li>
                    <li style={getValidateStyles(1)}>{t("errors.num")}</li>
                    <li style={getValidateStyles(2)}>{t("errors.lett")}</li>
                    <li style={getValidateStyles(3)}>{t("errors.uppLett")}</li>
                    <li style={getValidateStyles(4)}>{t("errors.specSymb")}</li>
                  </ul>
                </div>
              ) : null}

              <div
                className={s.inputBlock}
                style={
                  passwordValidate && resizeSmall
                    ? { marginTop: "6px" }
                    : { marginTop: "14px" }
                }
              >
                <label htmlFor="email">
                  {t("resetPassword.confirmPassword")}
                </label>
                <input
                  onChange={(e) => {
                    if (!e.target.value.includes(" ")) {
                      formik.handleChange(e);
                    }
                  }}
                  value={formik.values.confirm_password}
                  type={type.repeat}
                  id="confirm_password"
                  name="confirm_password"
                  placeholder={t("resetPassword.confirmPasswordPlaceholder")}
                  style={
                    !passwordValidate &&
                    formik.values.password !== formik.values.confirm_password &&
                    formik.values.confirm_password.length
                      ? { border: "1px solid #ff6464" }
                      : null
                  }
                />
                <div
                  onClick={() =>
                    type.repeat === "password"
                      ? setType((state) => {
                          return { ...state, repeat: "text" };
                        })
                      : setType((state) => {
                          return { ...state, repeat: "password" };
                        })
                  }
                  className={s.typeChangeBtn}
                >
                  {type.repeat === "password" ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>

              <div className={s.inputBlock} style={{ marginTop: "14px" }}>
                <label htmlFor="code">{t("resetPassword.code")}</label>
                <input
                  onChange={(e) => {
                    if (
                      e.target.value.length <= 6 &&
                      !e.target.value.includes(" ")
                    ) {
                      formik.handleChange(e);
                    }
                  }}
                  value={formik.values.code}
                  type={"text"}
                  id="code"
                  name="code"
                  placeholder={t("resetPassword.codePlaceholder")}
                />

                <span className={s.info}>
                  {t("cabinetEnter.checkEmailChangePass", {
                    userEmail: storedEmail,
                  })}
                </span>
              </div>

              <div className={s.resetPasswordBtnWrapper}>
                <button
                  disabled={
                    !formik.values.code ||
                    !formik.values.password ||
                    !formik.values.confirm_password ||
                    formik.values.password !== formik.values.confirm_password ||
                    formik.values.code.length < 6
                  }
                  className={s.resetPasswordBtn}
                  type="submit"
                >
                  {t("resetPassword.exit")}
                </button>
              </div>
            </form>
            <div className={s.noProfileWrapper}>
              <span className={s.noProfile}>{t("resetPassword.noCode")}</span>
              <span
                onClick={() => resendCodeOnClick()}
                className={
                  canResend ? s.noProfileLink : s.noProfileLinkDisabled
                }
              >
                {t("resetPassword.resendCode")}
                {timer ? ` ${t("resetPassword.in")} ${timer}` : null}
              </span>
            </div>
            <div className={s.resetPasswordWrapper}>
              <span className={s.noProfile}>
                {t("resetPassword.haveProfile")}
              </span>
              <Link to="/login" className={s.noProfileLink}>
                <span>{t("resetPassword.enter")}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
