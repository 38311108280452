import React, { useEffect, useState } from "react";
import Logo from "./Logo/Logo";
import Total from "../../assets/Wallet.png";
import s from "./Header.module.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Header = ({ openMobileTotal, setOpenMobileTotal }) => {
  const { t } = useTranslation();
  const hashRate = useSelector((state) => state.addWallet.hashrate);
  const coneSum = useSelector((state) => state.addWallet.CONESum);
  const totalIncome = useSelector((state) => state.addWallet.totalIncome);
  const [resize, setResize] = useState(false);

  let location = useLocation();

  useEffect(() => {
    const listenResizeEvent = () => {
      window.innerWidth <= 1080 ? setResize(true) : setResize(false);
    }
    listenResizeEvent();
    window.addEventListener("resize", listenResizeEvent, {passive: true});
    return () => window.removeEventListener("resize", listenResizeEvent);
  }, []);

  return (
    <header className={s.wrapper}>
      <Logo />

      {location.pathname !== "/login" &&
      location.pathname !== "/signup" &&
      location.pathname !== "/resetPassword" &&
      location.pathname !== "/signup/auth" &&
      (hashRate || coneSum || totalIncome) ? (
        <>
          {Boolean(coneSum) && (
            <div className={s.allConeWrapper}>
              <div className={s.allCone}>
                <span>{coneSum} CONE</span>
                <span>{t("available")}</span>
              </div>
            </div>
          )}
          {Boolean(hashRate) && (
            <div className={s.allConeWrapper}>
              <div className={s.allCone}>
                <span>{hashRate} TH/s</span>
                <span>{t("hash")}</span>
              </div>
            </div>
          )}
          {Boolean(totalIncome) && (
            <div className={s.allConeWrapper}>
              <div className={s.allCone}>
                <span>{totalIncome.toFixed(8)} BTC</span>
                <span>{t("totalIncome")}</span>
              </div>
            </div>
          )}
        </>
      ) : null}
      {(hashRate || coneSum || totalIncome) && resize ? (
        <>
          <img
            onClick={() => setOpenMobileTotal(!openMobileTotal)}
            className={s.total}
            src={Total}
            alt="total"
            style={{ maxWidth: "24px", maxHeight: "24px" }}
          />

          {openMobileTotal ? (
            <div className={s.totalContentWrapper}>
              {Boolean(coneSum) && (
                <div className={s.totalContent}>
                  <span>{coneSum} CONE</span>
                  <span>{t("available")}</span>
                </div>
              )}
              {Boolean(hashRate) && (
                <div className={s.totalContent}>
                  <span>{hashRate} TH/s</span>
                  <span>{t("hash")}</span>
                </div>
              )}
              {Boolean(totalIncome) && (
                <div className={s.totalContent}>
                  <span>{totalIncome.toFixed(8)} BTC</span>
                  <span>{t("totalIncome")}</span>
                </div>
              )}
            </div>
          ) : null}
          {openMobileTotal ? (
            <div
              onClick={() => setOpenMobileTotal(!openMobileTotal)}
              className={s.blur}
            ></div>
          ) : null}
        </>
      ) : null}
    </header>
  );
};

export default Header;
