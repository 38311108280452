import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DoneCircle from "../../assets/tick-circle.png";
import AddWalletCone from "../AddWallet/AddWalletCone";
import AddWalletBtc from "../AddWallet/AddWalletBtc";
import s from "./Main.module.css";
import Wallet from "./Wallet/Wallet";
import { useDispatch, useSelector } from "react-redux";
import {
  getMoreWallets,
  getWallets,
} from "../../redux/reducers/addWalletReducer";
import Loader from "../Loader/Loader";
import { AlertContext } from "../../App";

const Main = ({ openMenu }) => {
  const [openAddWallet, setOpenAddWallet] = useState({
    btc: false,
    cone: false,
  });
  const [openedPopup, setOpenedPopup] = useState(false)

  const walletsData = useSelector((state) => state?.addWallet?.wallets);
  const loading = useSelector((state) => state?.addWallet?.loading);
  const wallets = walletsData.data;
  const isBtcWalletAdded = wallets?.filter(
    (el) => el.currency === "BTC"
  ).length;
  const { meta } = walletsData;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const alert = useContext(AlertContext);

  useEffect(() => {
    if (!openAddWallet.btc && !openAddWallet.cone) {
      dispatch(getWallets(1, alert, t));
    }
    setOpenedPopup(openAddWallet.btc || openAddWallet.cone)
  }, [openAddWallet]);

  useEffect(() => {
    if (wallets?.length < 10 && meta?.total > 10) {
      dispatch(getWallets(1, alert, t));
    }
  }, [wallets]);

  const newUserCheck = () => {
    if (localStorage.getItem("newEmail") === localStorage.getItem("e")) {
      return (
        <div className={s.walletContent}>
          <div className={`${s.regSucces} ${openMenu ? s.regSuccesFull : null}`}>
            <img src={DoneCircle} />
            <span>{t("main.registrationSuccess")}</span>
          </div>
        </div>
      );
    } else if (!localStorage.getItem("newEmail") && !wallets?.length) {
      return <></>;
    }
  };

  return (
    <>
      <div className={`${s.wrapper} ${openMenu ? s.wrapperFull : null }`}>
        <div className={s.walletHeader}>
          <h2>{t("main.title")}</h2>

          <div className={s.btnGroup}>
            <button
              disabled={
                loading || wallets?.filter((el) => el.currency === "BTC").length
              }
              style={{ marginLeft: "auto", marginRight: "8px" }}
              className={s.addWalletBtn}
              onClick={() =>
                setOpenAddWallet((state) => {
                  return { ...state, btc: true };
                })
              }
              data-added={!!isBtcWalletAdded}
            >
              {t("main.addWalletBtcBtn")}
            </button>

            <button
              className={s.addWalletBtn}
              onClick={() =>
                setOpenAddWallet((state) => {
                  return { ...state, cone: true };
                })
              }
            >
              {t("main.addWalletConeBtn")}
            </button>
          </div>
        </div>

        {wallets?.length ? (
          <div className={`${s.allWalletsWrapper} ${openedPopup ? s.walletsFixed : ''}`}>
            {wallets.map((el) => (
              <Wallet key={el.id} data={el} />
            ))}
          </div>
        ) : null}

        {openAddWallet.cone ? (
          <AddWalletCone
            onClose={() =>
              setOpenAddWallet((state) => {
                return { ...state, cone: false };
              })
            }
          />
        ) : null}

        {openAddWallet.btc ? (
          <AddWalletBtc
            onClose={() =>
              setOpenAddWallet((state) => {
                return { ...state, btc: false };
              })
            }
          />
        ) : null}

        {walletsData?.meta?.current_page < walletsData?.meta?.last_page ? (
          <div
            onClick={() =>
              dispatch(getMoreWallets(walletsData?.meta?.current_page + 1, alert, t))
            }
            className={`${s.more} ${openedPopup ? s.moreFixed : ''}`}
          >
            {t("main.more")}
          </div>
        ) : null}
        {loading ? <Loader /> : null}
      </div>

      {!wallets?.length ? (
        newUserCheck()
      ) : null}

    </>
  );
};

export default Main;
