import React, { useContext, useState } from "react";
import s from "./Wallet.module.css";
import DeleteImg from "../../../assets/deleteImg.png";
import DeleteImgWh from "../../../assets/deleteImgWh.png";
import { useTranslation } from "react-i18next";
import { VscChromeClose } from "react-icons/vsc";
import ConeLogo from "../../../assets/cone.png";
import BtcLogo from "../../../assets/bitcoin.png";
import { useDispatch } from "react-redux";
import { deleteWallets } from "../../../redux/reducers/addWalletReducer";
import { AlertContext } from "../../../App";

const Wallet = ({ data }) => {
  const { t } = useTranslation();

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const alert = useContext(AlertContext);

  const dispatch = useDispatch();

  const { type } = data;
  const { address } = data;
  const { currency } = data;

  return (
    <div
      className={currency === "CONE" ? s.walletWrapperCone : s.walletWrapperBtc}
    >
      {openDeleteConfirm ? (
        <div
          className={s.confirmDeleteWrapper}
          onClick={(e) => {
            if (!e.target.attributes.hasOwnProperty("data-noclose")) {
              setOpenDeleteConfirm(false);
            }
          }}
        >
          <div data-noclose className={s.confirmDeleteContent}>
            <div
              data-noclose
              onClick={() => setOpenDeleteConfirm(false)}
              className={s.closeBtn}
            >
              <VscChromeClose data-noclose />
            </div>
            <span data-noclose className={s.title}>
              {t("main.confirmDelete.title")}
            </span>
            <div data-noclose className={s.content}>
              <span data-noclose>
                {t("main.confirmDelete.content", { address })}
              </span>
            </div>
            <div data-noclose className={s.footer}>
              <button onClick={() => setOpenDeleteConfirm(false)}>
                {t("main.confirmDelete.cancel")}
              </button>
              <button
                onClick={() => {
                  dispatch(deleteWallets({ ...data, alert, t }));
                  setOpenDeleteConfirm(false);
                }}
              >
                {t("main.confirmDelete.confirm")}
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <div className={s.header}>
        <img src={currency === "CONE" ? ConeLogo : BtcLogo} />
        <span
          data-currency={currency}
          data-maxwidth={data?.balance?.length > 18}
        >
          {data.balance}
        </span>
        <span>{data.currency}</span>

        <div onClick={() => setOpenDeleteConfirm(true)} className={s.delete}>
          <img src={currency === "CONE" ? DeleteImg : DeleteImgWh} />
        </div>
      </div>

      <div className={s.address}>
        <span>{t("main.wallet.address")}</span>
      </div>
      <div className={s.footer}>
        <span data-coin={currency}>{data.address}</span>
      </div>
    </div>
  );
};

export default Wallet;
