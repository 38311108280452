import { takeEvery, put, call } from "redux-saga/effects";
import {
  addError,
  GET_SIGN_UP_EMAIL,
  setResetPassEmail,
  setSigupEmail,
  verifyEmail,
} from "../reducers/signUpReducer";
import { api, getError } from "../../api/api";

export const SIGN_IN = "SIGN_IN";
export const SEND_VERIFY_CODE = "SEND_VERIFY_CODE";
export const RESEND_VERIFY_CODE = "RESEND_VERIFY_CODE";
export const SET_VERIFY_EMAIL = "SET_VERIFY_EMAIL";
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";

function* signUpWorker({ payload, alert, t }) {
  try {
    const res = yield call(api.signUp, payload);
    if (res.status === 200) {
      yield put(setSigupEmail(payload.email));
    }
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return
    }
    yield put(addError(getError(error).data.errors));
  }
}

function* verifyWorker({code, email, alert, t}) {
  try {
    const res = yield call(api.verifyAccount, {
      code,
      email,
    });

    if (res.status === 200) {
      yield put(verifyEmail());
      window.localStorage.setItem("newEmail", JSON.stringify(email));
    }
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return
    }
    if (getError(error).data.errors[0].message === "Code is incorrect") {
      alert("error", t("settings.incorectCode"));
    }
    yield put(addError(getError(error).data.errors));
  }
}

function* signInWorker({
  values,
  history,
  setOpenAuthLogin,
  codeForAuth,
  alert,
  t,
}) {
  try {
    const res = yield call(api.signIn, { ...values, code: codeForAuth });
    if (res.status === 200) {
      document.cookie = `BIT/${res.data.type}=${res.data.token}`;
      window.localStorage.setItem("e", JSON.stringify(values.email));
      history("/dashboard");
    }
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return
    }
    if (getError(error).status === 406) {
      setOpenAuthLogin(true);
    }
    getError(error).data.errors?.forEach((el) => {
      if (el.message === "Code is incorrect") {
        alert("error", t("settings.incorectCode"));
      }
    });
    yield put(addError(getError(error).data.errors));
  }
}

function* setNewPasswordWorker({ values, history, alert, t }) {
  try {
    const res = yield call(api.setNewPassword, values);
    if (res.status === 200) {
      yield put(setResetPassEmail(""));
      alert("success", t("success"));
      history("/login");
    }
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return
    }
    getError(error).data.errors.forEach((el) => {
      if (el.message === "Code is incorrect") {
        alert("error", t("settings.incorectCode"));
      } else if (el.message === "Invalid email format") {
        alert("error", t("invalidEmail"));
      } else if (el.message === "Previous password cannot be set") {
        alert("error", t("usedPreviousPassword"));
      }
    });
    yield put(addError(getError(error).data.errors));
  }
}

function* sendVerifyWorker({ email, lang, alert, t }) {
  try {
    yield call(api.resendVerifyCode, { email: email, lang: lang });
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return
    }
    yield put(addError(getError(error).data.errors));
  }
}

function* resendVerifyWorker({ email, lang, alert, t }) {
  try {
    const data = yield call(api.resendNewPasswordCode, { email: email, lang: lang });
    if (data.status === 200) {
      yield put(setResetPassEmail(email));
    }
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return
    }
    getError(error).data?.errors.forEach((el) => {
      if (el.message === "Invalid email format") {
        alert("error", t("invalidEmail"));
      } else if (el.message === "User not found") {
        alert("error", t("userNotFound"));
      }
    });
    yield put(addError(getError(error).data.errors));
  }
}

export function* signUpWatcher() {
  yield takeEvery(GET_SIGN_UP_EMAIL, signUpWorker);
  yield takeEvery(SET_VERIFY_EMAIL, verifyWorker);
  yield takeEvery(SIGN_IN, signInWorker);
  yield takeEvery(SEND_VERIFY_CODE, sendVerifyWorker);
  yield takeEvery(RESEND_VERIFY_CODE, resendVerifyWorker);
  yield takeEvery(SET_NEW_PASSWORD, setNewPasswordWorker);
}
