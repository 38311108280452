import React from "react";
import s from "./Loader.module.css";

const Loader = () => {
  return (
    <div className={s.wrapper}>
      <div id={s.loading}></div>
    </div>
  );
};

export default Loader;
