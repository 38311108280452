import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { VscChromeClose } from "react-icons/vsc";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import ErrorImg from "../../assets/err.png";
import { addError, setSigupEmail } from "../../redux/reducers/signUpReducer";
import s from "./LoginPage.module.css";
import { AlertContext } from "../../App";
import { SEND_VERIFY_CODE, SIGN_IN } from "../../redux/sagas/signUpSaga";

const ErrorsBlock = ({ errors }) => {
  const { t } = useTranslation();
  let userNotFound = errors && errors[0]?.message === "User not found";

  return (
    <div className={s.errorWrapper}>
      <img src={ErrorImg} />
      <div className={s.errorContent}>
        <span className={s.errorTitle}>{t("errors.login")}</span>
        {userNotFound ? (
          <span>
            {t("errors.notRegistered")}
            <Link to={"/signup"}>{t("errors.signUpLink")}</Link>
          </span>
        ) : (
          <div>
            <span>{t("errors.check")}</span>
            <div className={s.addErrorContent}>
              <li>{t("errors.caps")}</li>
              <li>{t("errors.ruengChange")}</li>
              <span>{t("errors.rueng")}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const LoginPage = () => {
  const currentLang = localStorage.getItem("i18nextLng");

  const [type, setType] = useState("password");
  const history = useNavigate();
  const dispatch = useDispatch();
  const [openAuthLogin, setOpenAuthLogin] = useState(false);
  const [codeForAuth, setCodeForAuth] = useState("");
  const [openLang, setOpenLang] = useState(false);
  const [lang, setLang] = useState(currentLang);
  const alert = useContext(AlertContext);

  const { t, i18n } = useTranslation();

  const errors = useSelector((state) => state.signUp.errors);
  const userNotVerified =
    !!errors && errors[0]?.message === "User not verified";

  useEffect(() => {
    return () => dispatch(addError(null));
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      dispatch({
        type: SIGN_IN,
        values: {
          ...values,
          email: values.email.toLocaleLowerCase(),
          lang: localStorage.getItem("i18nextLng"),
        },
        history,
        setOpenAuthLogin,
        codeForAuth,
        alert,
        t,
      });
    },
  });

  useEffect(() => {
    if (userNotVerified) {
      dispatch(setSigupEmail(formik.values.email.toLocaleLowerCase()));
      dispatch({
        type: SEND_VERIFY_CODE,
        email: formik.values.email.toLocaleLowerCase(),
        lang: localStorage.getItem("i18nextLng"),
        alert,
        t,
      });
      history("/signup/auth");
    }
  }, [errors]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLang(lang);
    setOpenLang(!openLang);
  };

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.login}>
          <div className={s.loginForm}>
            <div className={s.changeLangWrapper}>
              <div
                className={s.changeLang}
                onClick={() => setOpenLang(!openLang)}
              >
                <span>{lang.toUpperCase()}</span>
                {openLang ? (
                  <MdOutlineArrowBackIosNew
                    style={{ transform: "rotate(90deg)" }}
                  />
                ) : (
                  <MdOutlineArrowBackIosNew
                    style={{ transform: "rotate(270deg)" }}
                  />
                )}
              </div>
              {openLang ? (
                <div
                  className={s.select}
                  onClick={() => changeLanguage(lang === "ru" ? "en" : "ru")}
                >
                  <span>{lang === "ru" ? "EN" : "RU"}</span>
                </div>
              ) : null}
            </div>
            <div className={s.title}>
              <span>{t("login.enter")}</span>
            </div>
            <form onSubmit={formik.handleSubmit}>
              {openAuthLogin ? (
                <div
                  className={s.twoFactAuthWrapper}
                  onClick={(e) => {
                    if (!e.target.attributes.hasOwnProperty("data-2fa")) {
                      setOpenAuthLogin(false);
                      setCodeForAuth("");
                    }
                  }}
                >
                  <div className={s.twoFactAuthContent} data-2fa={true}>
                    <div
                      onClick={() => {
                        setOpenAuthLogin(false);
                        setCodeForAuth("");
                      }}
                      className={s.closeBtn}
                    >
                      <VscChromeClose />
                    </div>
                    <span className={s.title} data-2fa={true}>
                      {t("settings.authTitle")}
                    </span>
                    <div className={s.authContent} data-2fa={true}>
                      <input
                        type="text"
                        value={codeForAuth}
                        placeholder={t("settings.codePlaceholder")}
                        onChange={(e) => {
                          if (e.target.value.length <= 6)
                            setCodeForAuth(e.target.value);
                        }}
                        data-2fa={true}
                      />
                      <div className={s.footer} data-2fa={true}>
                        <button
                          data-2fa={true}
                          onClick={() => {
                            setOpenAuthLogin(false);
                            setCodeForAuth("");
                          }}
                        >
                          {t("main.confirmDelete.cancel")}
                        </button>
                        <button disabled={codeForAuth.length < 6} type="submit" data-2fa={true}>
                          {t("login.enterButton")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={s.inputBlock}>
                <label htmlFor="email">{t("login.email")}</label>
                <input
                  onChange={(e) => {
                    if (!e.target.value.includes(" ")) {
                      formik.handleChange(e);
                    }
                  }}
                  value={formik.values.email}
                  type={"text"}
                  id="email"
                  name="email"
                  placeholder={t("login.emailPlaceholder")}
                  style={
                    errors && !userNotVerified
                      ? { border: "1px solid #ff6464" }
                      : null
                  }
                />
              </div>
              <div className={s.inputBlock}>
                <label htmlFor="password">{t("login.password")}</label>
                <input
                  onChange={(e) => {
                    if (!e.target.value.includes(" ")) {
                      formik.handleChange(e);
                    }
                  }}
                  value={formik.values.password}
                  type={type}
                  id="password"
                  name="password"
                  placeholder={t("login.passwordPlaceholder")}
                  style={
                    errors && !userNotVerified
                      ? { border: "1px solid #ff6464" }
                      : null
                  }
                />
                <div
                  onClick={() =>
                    type === "password" ? setType("text") : setType("password")
                  }
                  className={s.typeChangeBtn}
                >
                  {type === "password" ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>

              {errors && !userNotVerified ? (
                <ErrorsBlock errors={errors} />
              ) : null}

              <div className={s.loginBtnWrapper}>
                <button
                  disabled={!formik.values.email || !formik.values.password}
                  className={s.loginBtn}
                  type="submit"
                >
                  {t("login.enterButton")}
                </button>
              </div>
            </form>
            <div className={s.noProfileWrapper}>
              <div>
                <span className={s.noProfile}>{t("login.noProfile")}</span>
                <Link to="/signup" className={s.noProfileLink}>
                  <span>{t("login.registration")}</span>
                </Link>
              </div>
              <Link
                to="/resetPassword"
                className={s.noProfileLink}
                style={{ marginTop: "8px" }}
              >
                <span>{t("login.forgotPass")}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
