import { addWalletWatcher } from "./addWalletSaga";
import { signUpWatcher } from "./signUpSaga";
import { settingsWatcher } from "./settingsSaga";
import { historyWatcher } from "./historySaga";

export default [
  signUpWatcher,
  addWalletWatcher,
  settingsWatcher,
  historyWatcher,
];
