import { useLocation, Navigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const auth = document.cookie?.indexOf("bearer") !== -1;

  if (!auth) {
    return <Navigate to={"/login"} state={{ from: location }} />;
  }

  return children;
};

export { RequireAuth };
