import React, { useContext, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import ErrorImg from "../../assets/err.png";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

import s from "./Registration.module.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addError,
  fetchEmail,
  logOut,
  setResetPassEmail,
} from "../../redux/reducers/signUpReducer";
import { RESEND_VERIFY_CODE } from "../../redux/sagas/signUpSaga";
import { AlertContext } from "../../App";

const ErrorsBlock = ({ errors, email }) => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);

  const dispatch = useDispatch();
  let alreadyExist = errors?.some(
    (el) => el.message === "Email already exists"
  );

  return (
    <div className={s.errorWrapper}>
      <img src={ErrorImg} />
      <div className={s.errorContent}>
        <span className={s.errorTitle}>{t("errors.signup")}</span>
        {alreadyExist ? (
          <span>
            {t("errors.emailExists")}
            <Link to={"/login"}>{t("errors.in")}</Link>
            {t("errors.or")}
            <Link
              to={"/resetPassword"}
              onClick={() => {
                dispatch(setResetPassEmail(email.toLocaleLowerCase()));
                dispatch({
                  type: RESEND_VERIFY_CODE,
                  email: email.toLocaleLowerCase(),
                  lang: localStorage.getItem("i18nextLng"),
                  alert,
                  t,
                });
              }}
            >
              {t("errors.changePass")}
            </Link>
          </span>
        ) : (
          <span>{t("errors.check")}</span>
        )}
      </div>
    </div>
  );
};

const Registration = () => {
  const currentLang = localStorage.getItem("i18nextLng");

  const [type, setType] = useState({ pass: "password", repeat: "password" });
  const [checkboxes, setCheckboxes] = useState({
    terms: false,
    notCitizen: false,
  });
  const [openLang, setOpenLang] = useState(false);
  const [lang, setLang] = useState(currentLang);

  const userEmail = useSelector((state) => state.signUp.signUp.email);
  const isVerify = useSelector((state) => state.signUp.signUp.verifyed);
  const errors = useSelector((state) => state.signUp.errors);
  const [passwordValidate, setPasswordValidate] = useState(false);
  const [resizeSmall, setResizeSmall] = useState(true);
  const [resizeBig, setResizeBig] = useState(false);
  const alert = useContext(AlertContext);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let history = useNavigate();

  useEffect(() => {
    const listenResizeEvent = () => {
      window.innerWidth <= 700 ? setResizeSmall(true) : setResizeSmall(false);
      window.innerWidth > 700 ? setResizeBig(true) : setResizeBig(false);
    }
    listenResizeEvent();
    window.addEventListener("resize", listenResizeEvent, {passive: true});
    return () => window.removeEventListener("resize", listenResizeEvent);
  }, []);

  useEffect(() => {
    if (userEmail) {
      dispatch(logOut());
    }
    return () => dispatch(addError(null));
  }, []);

  useEffect(() => {
    if (userEmail && !isVerify) {
      history("/signup/auth");
    }
  }, [userEmail, isVerify]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirm_password: "",
    },
    onSubmit: (values) => {
      if (
        values.password.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/
        ) !== null
      ) {
        dispatch(
          fetchEmail({
            ...values,
            email: values.email.toLocaleLowerCase(),
            lang: localStorage.getItem("i18nextLng")
          }, alert, t)
        );
      } else {
        setPasswordValidate(true);
        dispatch(addError(null));
      }
    },
  });

  const checkboxInputHandler = (n) => {
    n === "terms"
      ? setCheckboxes((state) => {
          return { ...state, terms: !state.terms };
        })
      : setCheckboxes((state) => {
          return { ...state, notCitizen: !state.notCitizen };
        });
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLang(lang);
    setOpenLang(!openLang);
  };

  const getValidateStyles = (el) => {
    if (el === 0) {
      if (
        formik.values.password?.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/
        )
      ) {
        return { color: "#2ac769" };
      } else {
        return { color: "#323232" };
      }
    } else if (el === 1) {
      if (formik.values.password?.match(/(?=.*\d)/)) {
        return { color: "#2ac769" };
      }
    } else if (el === 2) {
      if (formik.values.password?.match(/(?=.*[a-z])/)) {
        return { color: "#2ac769" };
      }
    } else if (el === 3) {
      if (formik.values.password?.match(/(?=.*[A-Z])/)) {
        return { color: "#2ac769" };
      }
    } else if (el === 4) {
      if (formik.values.password?.match(/(?=.*[@$!%*?&#^])/)) {
        return { color: "#2ac769" };
      }
    } else if (el === 5) {
      if (formik.values.password?.length > 7) {
        return { color: "#2ac769" };
      }
    } else if (el === "i") {
      if (
        !passwordValidate &&
        formik.values.password !== formik.values.confirm_password &&
        formik.values.confirm_password.length
      ) {
        return { border: "1px solid #ff6464" };
      } else if (
        passwordValidate &&
        formik.values.password?.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/
        )
      ) {
        return { border: "1px solid #2ac769" };
      } else if (passwordValidate && !!formik.values.password.length) {
        return { border: "1px solid #ff6464" };
      } else if (passwordValidate && !formik.values.password.length) {
        return { border: "1px solid #2ac769" };
      }
    }
  };

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.signup}>
          <div className={s.signupForm}>
            <div className={s.changeLangWrapper}>
              <div
                className={s.changeLang}
                onClick={() => setOpenLang(!openLang)}
              >
                <span>{lang.toUpperCase()}</span>
                {openLang ? (
                  <MdOutlineArrowBackIosNew
                    style={{ transform: "rotate(90deg)" }}
                  />
                ) : (
                  <MdOutlineArrowBackIosNew
                    style={{ transform: "rotate(270deg)" }}
                  />
                )}
              </div>
              {openLang ? (
                <div
                  className={s.select}
                  onClick={() => changeLanguage(lang === "ru" ? "en" : "ru")}
                >
                  <span>{lang === "ru" ? "EN" : "RU"}</span>
                </div>
              ) : null}
            </div>

            <div className={s.title}>
              <span>{t("registration.title")}</span>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className={s.inputBlock}>
                <label htmlFor="email">{t("registration.email")}</label>
                <input
                  onChange={(e) => {
                    if (!e.target.value.includes(" ")) {
                      formik.handleChange(e);
                    }
                  }}
                  value={formik.values.email}
                  type={"text"}
                  id="email"
                  name="email"
                  placeholder={t("registration.emailPlaceholder")}
                  style={errors ? { border: "1px solid #ff6464" } : null}
                />
              </div>

              <div className={s.inputBlock} style={{ position: "relative" }}>
                <label htmlFor="password">{t("registration.password")}</label>
                <input
                  onChange={(e) => {
                    if (!e.target.value.includes(" ")) {
                      formik.handleChange(e);
                    }
                  }}
                  value={formik.values.password}
                  type={type.pass}
                  id="password"
                  name="password"
                  placeholder={t("registration.passwordPlaceholder")}
                  style={getValidateStyles("i")}
                  onFocus={() => setPasswordValidate(true)}
                  onBlur={() => setPasswordValidate(false)}
                />
                <div
                  onClick={() =>
                    type.pass === "password"
                      ? setType((state) => {
                          return { ...state, pass: "text" };
                        })
                      : setType((state) => {
                          return { ...state, pass: "password" };
                        })
                  }
                  className={s.typeChangeBtn}
                >
                  {type.pass === "password" ? <FaEyeSlash /> : <FaEye />}
                </div>

                {passwordValidate && resizeBig ? (
                  <div className={s.passValidateWrapper}>
                    <span style={getValidateStyles(0)}>
                      {t("errors.passContain")}
                    </span>
                    <ul>
                      <li style={getValidateStyles(5)}>
                        {t("errors.minLength")}
                      </li>
                      <li style={getValidateStyles(1)}>{t("errors.num")}</li>
                      <li style={getValidateStyles(2)}>{t("errors.lett")}</li>
                      <li style={getValidateStyles(3)}>
                        {t("errors.uppLett")}
                      </li>
                      <li style={getValidateStyles(4)}>
                        {t("errors.specSymb")}
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>

              {passwordValidate && resizeSmall ? (
                <div className={s.passValidateWrapper}>
                  <span style={getValidateStyles(0)}>
                    {t("errors.passContain")}
                  </span>
                  <ul>
                    <li style={getValidateStyles(5)}>
                      {t("errors.minLength")}
                    </li>
                    <li style={getValidateStyles(1)}>{t("errors.num")}</li>
                    <li style={getValidateStyles(2)}>{t("errors.lett")}</li>
                    <li style={getValidateStyles(3)}>{t("errors.uppLett")}</li>
                    <li style={getValidateStyles(4)}>{t("errors.specSymb")}</li>
                  </ul>
                </div>
              ) : null}

              <div className={s.inputBlock}>
                <label htmlFor="confirm_password">
                  {t("registration.repeatPassword")}
                </label>
                <input
                  onChange={(e) => {
                    if (!e.target.value.includes(" ")) {
                      formik.handleChange(e);
                    }
                  }}
                  value={formik.values.confirm_password}
                  type={type.repeat}
                  id="confirm_password"
                  name="confirm_password"
                  placeholder={t("registration.repeatPassword")}
                  style={
                    !passwordValidate &&
                    formik.values.password !== formik.values.confirm_password &&
                    formik.values.confirm_password.length
                      ? { border: "1px solid #ff6464" }
                      : null
                  }
                />
                <div
                  onClick={() =>
                    type.repeat === "password"
                      ? setType((state) => {
                          return { ...state, repeat: "text" };
                        })
                      : setType((state) => {
                          return { ...state, repeat: "password" };
                        })
                  }
                  className={s.typeChangeBtn}
                >
                  {type.repeat === "password" ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>

              <div className={s.agreeInfoWrapper}>
                <div className={s.agreeInfo} style={{ margin: "24px 0 8px" }}>
                  <input
                    className={s.checkbox}
                    type="checkbox"
                    name="agree"
                    id="agree"
                    checked={checkboxes.terms}
                    onChange={() => checkboxInputHandler("terms")}
                  />
                  <label htmlFor="agree">
                    {checkboxes.terms ? <FaCheck /> : null}
                  </label>
                  <span>
                    {t("registration.agree.agree1")}
                    <a>{t("registration.agree.agree2")}</a>
                    {t("registration.agree.agree3")}
                    <a href={"/download/Privacy-Policy.pdf"} target="_blank" rel="noreferrer">{t("registration.agree.agree4")}</a>
                  </span>
                </div>
                <div className={s.agreeInfo}>
                  <input
                    className={s.checkbox}
                    type="checkbox"
                    name="notCitizen"
                    id="notCitizen"
                    checked={checkboxes.notCitizen}
                    onChange={() => checkboxInputHandler()}
                  />
                  <label htmlFor="notCitizen">
                    {checkboxes.notCitizen ? <FaCheck /> : null}
                  </label>
                  <span>{t("registration.notCitizen")}</span>
                </div>
              </div>

              {errors ? (
                <ErrorsBlock errors={errors} email={formik.values.email.toLocaleLowerCase()} />
              ) : null}

              <div className={s.signupBtnWrapper}>
                <button
                  disabled={
                    !formik.values.email ||
                    !formik.values.password ||
                    !formik.values.confirm_password ||
                    formik.values.password !== formik.values.confirm_password ||
                    !checkboxes.terms ||
                    !checkboxes.notCitizen
                  }
                  className={s.loginBtn}
                  type="submit"
                >
                  {t("registration.enterButton")}
                </button>
              </div>
            </form>
            <div className={s.loginWrapper}>
              <span className={s.noProfile}>
                {t("registration.haveProfile")}
              </span>
              <Link to="/login" className={s.loginLink}>
                <span>{t("registration.login")}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
