import signUpReducer from "./signUpReducer";
import addWalletReducer from "./addWalletReducer";
import settingsReducer from "./settingsReducer";
import historyReducer from "./historyReducer";

export default {
  signUp: signUpReducer,
  addWallet: addWalletReducer,
  settings: settingsReducer,
  history: historyReducer,
};
