import React from "react";
import SuccesTost from "../../assets/succes-tost.png";
import FailTost from "../../assets/fail-tost.png";
import s from "./Alert.module.css";
import { toast } from "react-toastify";

export const Alert = (type, message) => {
  switch (type) {
    case "error":
      return toast(
        <div className={s.tostWrapper}>
          <img src={FailTost} />
          <span>{message}</span>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: true,
          className: s.errorToast,
        }
      );
    case "success":
      return toast(
        <div className={s.tostWrapper}>
          <img src={SuccesTost} />
          <span>{message}</span>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: true,
          className: s.successToast,
        }
      );
    default:
      return toast(message);
  }
};
