export const SET_WALLET_VALUE = "SET_WALLET_VALUE";
export const SET_SIGNATURE_VALUE = "SET_SIGNATURE_VALUE";
export const SET_SIGNATURE_PHRASE_VALUE = "SET_SIGNATURE_PHRASE_VALUE";
export const GET_SIGNATURE_PHRASE_VALUE = "GET_SIGNATURE_PHRASE_VALUE";
export const ADD_CONE_WALLET = "ADD_CONE_WALLET";
export const ADD_BTC_WALLET = "ADD_BTC_WALLET";
export const FAIL_ADD_WALLET = "FAIL_ADD_WALLET";
export const GET_WALLETS = "GET_WALLETS";
export const GET_MORE_WALLETS = "GET_MORE_WALLETS";
export const DELETE_WALLETS = "DELETE_WALLETS";
export const SET_WALLETS = "SET_WALLETS";
export const ADD_WALLETS = "ADD_WALLETS";
export const SET_HASHRATE = "SET_HASHRATE";
export const GET_HASHRATE = "GET_HASHRATE";
export const LOG_OUT_WALLETS = "LOG_OUT_WALLETS";
export const LOADING = "LOADING";

const initialState = {
  addWallet: {
    walletAdress: "",
    blockchain: "",
  },
  phrase: "",
  signature: "",
  error: [],
  wallets: { data: [], meta: {} },
  CONESum: null,
  hashrate: null,
  totalIncome: null,
  loading: false,
};

export default function addWalletReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_WALLET_VALUE:
      return {
        ...state,
        addWallet: {
          walletAdress: action.payload.walletAdress,
          blockchain: action.payload.blockchain,
        },
      };
    case SET_SIGNATURE_VALUE:
      return {
        ...state,
        signature: action.payload,
      };
    case SET_SIGNATURE_PHRASE_VALUE:
      return {
        ...state,
        phrase: action.payload.phrase,
      };
    case FAIL_ADD_WALLET:
      return {
        ...state,
        error: action.payload,
      };
    case SET_WALLETS:
      return {
        ...state,
        wallets: action.payload,
      };
    case ADD_WALLETS:
      return {
        ...state,
        wallets: {
          meta: action.payload.meta,
          data: [...state.wallets.data, ...action.payload.data],
        },
      };
    case DELETE_WALLETS:
      return {
        ...state,
        wallets: {
          meta: state.wallets.meta,
          data: [
            ...state.wallets?.data?.filter((el) => el.id !== action.payload),
          ],
        },
      };
    case SET_HASHRATE:
      return {
        ...state,
        CONESum: action.payload.CONESum,
        hashrate: action.payload.hashrate,
        totalIncome: action.payload.totalIncome,
      };
    case LOG_OUT_WALLETS:
      return {
        addWallet: {
          walletAdress: "",
          blockchain: "",
        },
        phrase: "",
        signature: "",
        error: [],
        wallets: { data: [], meta: {} },
        CONESum: null,
        hashrate: null,
        totalIncome: null,
        loading: false,
      };
    default:
      return state;
  }
}

export const setWalletValue = (payload) => ({
  type: SET_WALLET_VALUE,
  payload,
});

export const logOutWallets = () => ({
  type: LOG_OUT_WALLETS,
});

export const setSignatureValue = (payload) => ({
  type: SET_SIGNATURE_VALUE,
  payload,
});

export const getSignaturePhraseValue = (payload, alert, t) => ({
  type: GET_SIGNATURE_PHRASE_VALUE,
  payload,
  alert,
  t,
});

export const setSignaturePhraseValue = (payload) => ({
  type: SET_SIGNATURE_PHRASE_VALUE,
  payload,
});

export const addConeWallet = (payload, alert, t) => ({
  type: ADD_CONE_WALLET,
  payload,
  alert,
  t,
});

export const addBtcWallet = (payload, alert, t) => ({
  type: ADD_BTC_WALLET,
  payload,
  alert,
  t,
});

export const addWalletFail = (payload) => ({
  type: FAIL_ADD_WALLET,
  payload,
});

export const getWallets = (payload, alert, t) => ({
  type: GET_WALLETS,
  payload,
  alert,
  t,
});

export const getMoreWallets = (payload, alert, t) => ({
  type: GET_MORE_WALLETS,
  payload,
  alert,
  t,
});

export const setWallets = (payload) => ({
  type: SET_WALLETS,
  payload,
});

export const addWallets = (payload) => ({
  type: ADD_WALLETS,
  payload,
});

export const deleteWallets = (payload) => ({
  type: DELETE_WALLETS,
  payload,
});

export const getHashrate = () => ({
  type: GET_HASHRATE,
});

export const setHashrate = (payload) => ({
  type: SET_HASHRATE,
  payload,
});

export const setLoading = (payload) => ({
  type: LOADING,
  payload,
});
