export const SET_HISTORY_VALUES = "SET_HISTORY_VALUES";

const initialState = {
  data: [],
};

export default function historyReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HISTORY_VALUES:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}

export const setHistoryValue = (payload) => ({
  type: SET_HISTORY_VALUES,
  payload,
});
