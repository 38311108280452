import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AlertContext } from "../../App";
import { addError } from "../../redux/reducers/signUpReducer";
import {
  SEND_VERIFY_CODE,
  SET_VERIFY_EMAIL,
} from "../../redux/sagas/signUpSaga";
import s from "./CabinetEnter.module.css";

const CabinetEnter = () => {
  const verify = useSelector((state) => state.signUp.signUp.verified);
  const userEmail = useSelector((state) => state.signUp.signUp.email);
  const alert = useContext(AlertContext);

  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const [canResend, setCanResend] = useState(true);
  const [timer, setTimer] = useState(0);

  const dispatch = useDispatch();

  const history = useNavigate();

  useEffect(() => {
    if (!userEmail) {
      history("/signup");
    }
    if (verify) {
      history("/login");
    }
  }, [verify, userEmail]);

  useEffect(() => {
    return () => {
      dispatch(addError(null));
    };
  }, []);

  useEffect(() => {
    if (timer !== 0 && timer >= 1) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 990);
    }
  }, [timer]);

  const resendCodeOnClick = () => {
    if (canResend) {
      dispatch({
        type: SEND_VERIFY_CODE,
        email: userEmail,
        lang: localStorage.getItem("i18nextLng"),
        alert,
        t,
      });
      setCanResend(false);
      setTimer(10);

      setTimeout(() => {
        setCanResend(true);
      }, [10000]);
    }
  };

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.login}>
          <div className={s.loginForm}>
            <div className={s.title}>
              <span>{t("cabinetEnter.title")}</span>
            </div>
            <div className={s.inputBlock}>
              <label htmlFor="email">{t("cabinetEnter.code")}</label>
              <input
                onChange={(e) => {
                  {
                    if (
                      e.target.value.length <= 6 &&
                      !e.target.value.includes(" ")
                    )
                      setCode(e.target.value);
                  }
                }}
                value={code}
                type={"text"}
                id="email"
                name="email"
                placeholder={t("cabinetEnter.codePlaceholder")}
              />

              <span className={s.info}>
                {t("cabinetEnter.checkEmail", { userEmail })}
              </span>
            </div>
            <div className={s.loginBtnWrapper}>
              <button
                disabled={!code || code.length < 6}
                className={s.loginBtn}
                onClick={() =>
                  dispatch({
                    type: SET_VERIFY_EMAIL,
                    email: userEmail,
                    code,
                    alert,
                    t,
                  })
                }
              >
                {t("cabinetEnter.enter")}
              </button>
            </div>
            <div className={s.noProfileWrapper}>
              <span className={s.noProfile}>{t("cabinetEnter.noCode")}</span>
              <span
                className={
                  canResend ? s.noProfileLink : s.noProfileLinkDisabled
                }
                onClick={() => resendCodeOnClick()}
              >
                <span>
                  {t("resetPassword.resendCode")}
                  {timer ? ` ${t("resetPassword.in")} ${timer}` : null}
                </span>
              </span>
            </div>
            <div className={s.loginWrapper}>
              <span className={s.noProfile}>
                {t("cabinetEnter.haveProfile")}
              </span>
              <Link to="/login" className={s.noProfileLink}>
                <span>{t("login.enter")}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CabinetEnter;
