import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaBars } from "react-icons/fa";
import { RiDashboardLine } from "react-icons/ri";
import { RiHistoryLine } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import LogoutImg from "../../assets/logout.png";
import SupportImg from "../../assets/support.svg";
import s from "./Dashboard.module.css";
import { useTranslation } from "react-i18next";
import { logOut } from "../../redux/reducers/signUpReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  getHashrate,
  logOutWallets,
} from "../../redux/reducers/addWalletReducer";
import { GET_SETTING_VALUES } from "../../redux/reducers/settingsReducer";

const Dashboard = ({ setOpenMobileTotal, open, setOpen }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const wallets = useSelector((state) => state.addWallet?.wallets?.data);
  const [resizeSmall, setResizeSmall] = useState(true);
  const [resizeBig, setResizeBig] = useState(false);

  let email = JSON.parse(window.localStorage.getItem("e"));

  useEffect(() => {
    const listenResizeEvent = () => {
      window.innerWidth <= 700 ? setResizeSmall(true) : setResizeSmall(false);
      window.innerWidth > 700 ? setResizeBig(true) : setResizeBig(false);
    }
    listenResizeEvent();
    window.addEventListener("resize", listenResizeEvent, {passive: true});
    return () => window.removeEventListener("resize", listenResizeEvent);
  }, []);

  useEffect(() => {
    if (document.cookie?.indexOf("bearer") === -1) {
      history("/login");
    } else if (location.pathname === "/") {
      history("/dashboard");
    }
    dispatch({ type: GET_SETTING_VALUES });
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      history("/dashboard");
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getHashrate());
  }, [wallets]);

  useEffect(() => {
    if (open) {
      setOpenMobileTotal(false);
    }
  }, [open]);

  const logOutHandle = () => {
    document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    window.localStorage.removeItem("e");
    dispatch(logOut());
    dispatch(logOutWallets());
    history("/login");
  };

  return (
    <>
      <div className={s.wrapper}>
        <input
          onChange={() => setOpen(!open)}
          checked={open}
          type="checkbox"
          id="nav-toggle"
          hidden
        />

        {open && resizeSmall ? (
          <div onClick={() => setOpen(!open)} className={s.hoverAll}></div>
        ) : null}
        <nav
          className={s.nav}
          data-ismobileclose={!open && resizeSmall}
        >
          <label htmlFor="nav-toggle" className={s.navToggle}>
            {open ? (
              <>
                <FaArrowLeft />
                <span className={s.email}>{email ? email.toLocaleLowerCase() : null}</span>
              </>
            ) : (
              <FaBars />
            )}
          </label>

          <div className={s.emailBlock} data-open={open}></div>

          <ul style={!open ? { visibility: "hidden" } : null}>
            <li>
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  isActive ? s.active + " " + s.menuLink : s.menuLink
                }
                onClick={() =>
                  resizeSmall ? setOpen(!open) : null
                }
              >
                <RiDashboardLine style={{ transform: "scale(1, -1)" }} />
                <span>{t("dashboard.main")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/history"
                className={({ isActive }) =>
                  isActive ? s.active + " " + s.menuLink : s.menuLink
                }
                onClick={() =>
                  resizeSmall ? setOpen(!open) : null
                }
              >
                <RiHistoryLine />
                <span>{t("dashboard.history")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings"
                className={({ isActive }) =>
                  isActive ? s.active + " " + s.menuLink : s.menuLink
                }
                onClick={() =>
                  resizeSmall ? setOpen(!open) : null
                }
              >
                <FiSettings />
                <span>{t("dashboard.settings")}</span>
              </NavLink>
            </li>
          </ul>

          {resizeBig ? (
            <ul
              className={s.shortMenu}
              style={open ? { visibility: "hidden" } : null}
            >
              <li>
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    isActive ? s.active + " " + s.menuLink : s.menuLink
                  }
                >
                  <RiDashboardLine style={{ transform: "scale(1, -1)" }} />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/history"
                  className={({ isActive }) =>
                    isActive ? s.active + " " + s.menuLink : s.menuLink
                  }
                >
                  <RiHistoryLine />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/settings"
                  className={({ isActive }) =>
                    isActive ? s.active + " " + s.menuLink : s.menuLink
                  }
                >
                  <FiSettings />
                </NavLink>
              </li>
              <div className={s.bottomWrapperMin}>
                <a href="mailto:investor@cryptoone.farm" className={s.bottomItem}>
                  <img src={SupportImg} alt="support" />
                </a>
                <div className={`${s.bottomItem} ${s.logout}`} onClick={logOutHandle}>
                  <img src={LogoutImg} alt="logout" />
                </div>
              </div>
            </ul>
          ) : null}

          {open && (
            <div className={s.bottomWrapper}>
              <a href="mailto:investor@cryptoone.farm" className={s.bottomItem}>
                <img src={SupportImg} alt="support" />
                <span>{t("dashboard.support")}</span>
              </a>
              <div className={`${s.bottomItem} ${s.logout}`} onClick={logOutHandle}>
                <img src={LogoutImg} className={s.logout} alt="logout" />
                <span>{t("dashboard.exit")}</span>
              </div>
            </div>
          )}
        </nav>

        <Outlet />
      </div>
    </>
  );
};

export default Dashboard;
