export const GET_SETTING_VALUES = "GET_SETTING_VALUES";
export const SET_SETTING_VALUES = "SET_SETTING_VALUES";
export const SET_ERRORS = "SET_ERRORS";
export const SET_LANGUAGE = "SET_LANGUAGE";

const initialState = {
  lang: "en",
  twoFactorVerify: "false",
  errors: null,
};

export default function addWalletReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SETTING_VALUES:
      return {
        ...state,
        lang: action.payload.lang,
        twoFactorVerify: action.payload.tfa,
      };
    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
}

export const setWalletValue = (payload) => ({
  type: SET_SETTING_VALUES,
  payload,
});

export const setErrors = (payload) => ({
  type: SET_ERRORS,
  payload,
});

export const setLanguage = (payload) => ({
  type: SET_LANGUAGE,
  payload,
});
