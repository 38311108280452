import { takeEvery, takeLatest, put, call } from "redux-saga/effects";
import { api, getError } from "../../api/api";
import {
  GET_SETTING_VALUES,
  setErrors,
  setWalletValue,
} from "../reducers/settingsReducer";

export const SET_BACKEND_LANG = "SET_BACKEND_LANG";
export const UPDATE_PASSWORD = "SET_BACKEND_LANG";
export const GET_QR_URL = "GET_QR_URL";
export const VERIFY_TWO_FA = "VERIFY_TWO_FA";
export const REMOVE_TWO_FA = "REMOVE_TWO_FA";

function* settingsWorker({ alert, t }) {
  try {
    const res = yield call(api.getSettings);
    yield put(setWalletValue(res.data));
  } catch (error) {
    if (getError(error).status === 0) {
      if (alert && t) {
        alert("error", t("networkError"));
      }
      return
    }
    yield put(setErrors(getError(error).data.errors));
  }
}

function* updateLanguageWorker({ lang, alert, t }) {
  try {
    if (lang) {
      yield call(api.updateLanguage, { lang });
    }
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else yield put(setErrors(getError(error).data.errors));
  }
}

function* passwordUpdateWorker({
  oldPassword,
  password,
  confirmPassword,
  alert,
  t,
  setOpenChangePassword,
}) {
  try {
    if (oldPassword && password && confirmPassword) {
      let res = yield call(api.updatePassword, {
        oldPassword,
        password,
        confirmPassword,
      });
      if (res.status === 200) {
        alert("success", t("success"));
        setOpenChangePassword(false);
      }
    }
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else {
      alert("error", t("invalid"));
      yield put(setErrors(getError(error).data.errors));
    }
  }
}

function* getQrUrlWorker({ cb, alert, t }) {
  try {
    const res = yield call(api.add2FA);
    cb(res.data.url);
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else yield put(setErrors(getError(error).data.errors));
  }
}

function* verifyTwoFAWorker({
  authCode,
  setAuthSwitch,
  setOpenAuth,
  alert,
  t,
}) {
  try {
    const res = yield call(api.verify2FA, { code: authCode });
    if (res.status === 200) {
      setAuthSwitch(true);
      setOpenAuth(false);
      alert("success", t("success"));
    }
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else yield put(setErrors(getError(error).data.errors));
  }
}

function* removeTwoFAWorker({
  authCode,
  setAuthSwitch,
  setOpenAuth,
  alert,
  t,
}) {
  try {
    const res = yield call(api.remove2FA, { code: authCode });
    if (res.status === 200) {
      setAuthSwitch(false);
      setOpenAuth(false);
      alert("success", t("success"));
    }
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else yield put(setErrors(getError(error).data.errors));
  }
}

export function* settingsWatcher() {
  yield takeEvery(GET_SETTING_VALUES, settingsWorker);
  yield takeEvery(SET_BACKEND_LANG, updateLanguageWorker);
  yield takeEvery(GET_QR_URL, getQrUrlWorker);
  yield takeEvery(VERIFY_TWO_FA, verifyTwoFAWorker);
  yield takeEvery(REMOVE_TWO_FA, removeTwoFAWorker);
  yield takeEvery(UPDATE_PASSWORD, passwordUpdateWorker);
}
